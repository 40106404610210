
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  beforeMount() {
    // addEventListener(
    //   document,
    //   'touchstart',
    //   function (e) {
    //     console.log(e.defaultPrevented); // will be false
    //     e.preventDefault(); // does nothing since the listener is passive
    //     console.log(e.defaultPrevented); // still false
    //   },
    //   Modernizr.passiveeventlisteners ? { passive: true } : false
    // );
  }
};
