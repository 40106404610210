import { render, staticRenderFns } from "./YoutubeVideo.vue?vue&type=template&id=c2c86386&scoped=true&"
import script from "./YoutubeVideo.vue?vue&type=script&lang=js&"
export * from "./YoutubeVideo.vue?vue&type=script&lang=js&"
import style0 from "./YoutubeVideo.vue?vue&type=style&index=0&id=c2c86386&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2c86386",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Section: require('/vercel/path0/components/elements/Section.vue').default})
